.content-panel {
    .navbar {
        position: fixed;
        padding: 0;
        height: 56px;
        z-index: 2;
        width: 85%;
        border-bottom: 1px solid rgba(23, 36, 61, 0.1);
        background-color: white;
        .navbar-text {
            font-size: 14px;
            a.logged-in-username {
                color: $gold;
                text-decoration: none;
                letter-spacing: 0.25px;
                font-weight: 500;
                font-size: 14px;
            }
        }
    }
}
