.story-details-modal {
    .story-title {
        text-align: center;
        margin-bottom: 10px;
    }
    .access-users {
        background-color: lightgray;
        padding: 5px;
        .access-user-container {
            display: inline-block;
            background-color: white;
            margin: 3px;
            padding: 3px;
            border-radius: 7px;
            cursor: pointer;

            &:hover {
                background-color: $lightgray;
            }

            .access-user {
                color: $base !important;
                text-decoration: none !important;
                font-size: 12px;
            }
        }
    }
}
