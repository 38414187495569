.login-container {
    max-width: 500px;
    padding: 50px;
    text-align: center;
    margin: auto;

    @media screen and (max-width: 480px) {
        padding: 20px;
    }

    .login-top-row {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 50px;
        border-bottom: 0.5px solid rgba(23, 36, 61, 0.1);
        .logo-login {
            width: 35px;
            height: 35px;
        }

       
        .title {
            color: $base;
            font-size: 20px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 24px;
            text-align: center;
            margin: 0;
        }
    }

    .login-form {
        .string-input {
            width: 100%;
            margin-bottom: 48px;
            padding: 15px;
            font-weight: 300;
            min-width: fit-content;
            @media screen and (min-width: 1000px) {
                max-width: 100%!important;
            }
        }

        .saved-user-container {
            margin-bottom: 48px;
            margin-top: 30px;
            p {
                margin: 0;
            }

            .name {
                font-weight: 700;
                font-size: 16px;
            }

            .email {
                margin-bottom: 16px;
                font-weight: 300;
                color: $gray;
            }

            .user-picture {
                height: 80px;
                width: 80px;
                margin-bottom: 10px;
            }
        }
    }

    .button-group {
        .login-button {
            padding: 10px;
            width: 100%;

            .icon {
                margin: 0 10px;
                width: 18px;
            }
        }

        @media screen and (min-width: 320px) {
            .default-button {
                max-width: 100%;
            }
        }
    }

    .password-input {
        position: relative;

        svg {
            position: absolute;
            top: 19px;
            right: 17px;
            color: $gray;
            width: 22px;
            cursor: pointer;
        }
    }
}
