//Variables
$base: #17243d;
$secondary: #fff;
$base-green: #006238;
$base-green-hover: #009454;
$gold: #a38138;
$blue: #4569ad;
$gray: rgba($base, 0.5);
$fullgray: #16243c;
$darkgray: #17243d;
$lightgray: #f3f4f5;
$custom_white: rgba(white, 0.9);
$red: #ef5a5a;
$lightred: rgb(238, 109, 109);
$font: "DM Sans", sans-serif;
$transition: all 0.2s linear;

$gray-color-line: rgba(23, 36, 61, 0.12);
$gray-color-border: rgba(23, 36, 61, 0.1);
$gray-color-medium: rgba(23, 36, 61, 0.7);

// sidebar
$sidebar-bg-color: $secondary;
$sidebar-color: $gray-color-medium;
$sidebar-width: 270px !default;
$sidebar-collapsed-width: 80px !default;
$highlight-color: $gold;
$submenu-bg-color: $lightgray !default;
$submenu-bg-color-collapsed: $lightgray !default;
$icon-bg-color: $secondary;
$icon-size: 35px !default;
$submenu-indent: 24px !default;
$breakpoint-xs: 480px !default;
$breakpoint-sm: 576px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 992px !default;
$breakpoint-xl: 1200px !default;
$breakpoint-xxl: 1600px !default;
