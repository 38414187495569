.profile-info {
    padding-bottom: 42px;

    .top-section {
        justify-content: center;

        .cover-container {
            overflow: hidden;
            position: absolute;
            padding: 0;

            .user-picture-cover {
                width: 100%;
                height: 95px;
                object-fit: cover;
                filter: blur(5px);
            }
        }
    }

    .avatar {
        text-align: center;
        padding-top: 20px;
        position: relative;

        img {
            width: 150px;
            height: 150px;
            margin-bottom: 20px;
            object-fit: cover;
        }

        button {
            display: block;
            margin: auto;
        }
    }
    .small-profile-image {
        max-width: 30px;
    }

    .relation {
        text-transform: capitalize;
        margin-bottom: 2px;
        color: $gray;
    }

    .life-dates {
        color: $gray;
    }

    .admin-notification {
        max-width: 335px;
        margin: auto;
        border-radius: 2px;
        background-color: #f75f5f;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
        padding: 12px;
        margin-top: 20px;
        margin-bottom: 10px;

        &.centered {
            text-align: center;
        }

        p {
            font-size: 12px;
            letter-spacing: 0.5px;
            line-height: 18px;
            color: $secondary;
            margin: 0;

            a {
                color: $secondary;
            }
        }
    }

    .section {
        margin: 70px auto;
    }

    .info-container {
        display: block;
    }

    .info-section {
        margin: auto;
        padding: 0;

        &.admin,
        &.successor,
        &.featured,
        &.stories,
        &.details {
            max-width: 60%;
        }

        .title {
            margin-bottom: 8px;
            text-align: center;
            line-height: 32px;

            svg {
                color: $gray;
                font-size: 16px;
                margin-left: 5px;
            }
        }

        .subtitle {
            font-size: 13px;
            line-height: 18px;
            color: $gray;
            text-align: center;
            margin-bottom: 0;
        }

        &.admin {
            .admin-list {
                margin-top: 36px;
                .admin-list-item {
                    margin-bottom: 12px;

                    a {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    img {
                        width: 24px;
                        height: 24px;
                        margin-right: 8px;
                    }
                }
            }
        }

        .info-item {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid rgba($base, 0.05);

            &:last-child {
                border: none;
            }

            p {
                margin: 0;
                font-size: 16px;
                line-height: 56px;
                font-weight: 600;
                color: $base;

                svg {
                    color: $gray;
                    width: 16px;
                    margin-right: 12px;
                }
            }

            .btn-container {
                display: flex;
                align-items: center;

                button {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    border: 1px solid $base-green;
                    color: $base-green;
                    font-weight: 600;
                    background-color: transparent;
                }

                button:hover {
                    cursor: pointer;
                    /*
                    color: $base-green-hover;
                    border-color: $base-green-hover;
                    */
                    background-color: $base-green-hover;
                    color: $custom_white;
                }
            }
        }
    }

    .details {
        .detail-item {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid rgba($base, 0.05);
            flex-direction: column;

            .infos {
                display: flex;
                justify-content: space-between;

                &.related {
                    border: none;
                }

                p {
                    margin: 0;
                    line-height: 48px;
                }

                .label {
                    color: $gray;
                }

                .detail-text {
                    display: flex;
                    align-items: center;

                    svg {
                        color: $base-green;
                        margin-left: 15px;

                        &.editable {
                            cursor: pointer;
                            &:hover {
                                color: $base-green-hover;
                            }
                        }

                        &.email {
                            color: $gray;
                        }
                    }
                }
            }
        }

        .edit {
            border: 2px solid $gold;
            position: relative;
            padding-left: 10px;
            height: 72px;

            .top-label {
                font-size: 13px !important;
                padding: 5px 5px 0;
                color: rgba(23, 36, 61, 0.7) !important;
                z-index: 10;
                line-height: 19px !important;
                margin-bottom: 0;
            }

            input {
                border: none !important;
                padding: 0 5px;
                height: 50px !important;
            }

            .PhoneInput {
                margin-left: 5px;
            }

            .submit-icon-container {
                position: absolute;
                width: 20px;
                right: 30px;
                top: 33%;
            }

            .calendar-icon {
                color: $gray;
            }

            .form-select {
                width: 90%;
                height: 40px !important;
            }

            .change-profiletype {
                position: absolute;
                top: 45%;
                margin-left: 5px;
            }

            .refuse {
                color: #ef5a5a;
            }
            .refuse:hover {
                color: #ec7878;
                cursor: pointer;
            }

            svg {
                color: $base-green;
                margin-left: 15px;
                cursor: pointer;

                &:hover {
                    color: $base-green-hover;
                }
            }
        }

        .related-websites {
            padding-top: 5px;

            .website-item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 10px;

                > div {
                    display: flex;
                    align-items: center;

                    svg {
                        margin-right: 10px;
                    }

                    a {
                        margin: 0;
                        color: $base-green;
                    }
                }

                svg {
                    color: $gray;
                    cursor: pointer;

                    &:hover {
                        color: $darkgray;
                    }
                }
            }
        }
    }

    .stories-number {
        padding: 5px;
        border: 1px solid $gray;
        border-radius: 50%;
        text-align: center;
        width: 35px;
        float: right;
    }

    .search-form {
        border: 1px solid $gray;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;

        input {
            border: none !important;
            width: 90%;
        }

        label {
            width: 10%;
        }
    }

    .user-list {
        padding: 5px;
        margin: 10px 0;
        border: 1px solid $gray;
        border-radius: 30px;
        max-width: 400px;
    }
}

.profile-info, .profile-container {
    h4 {
        font-size: 18px;
        padding-top: 40px;
    }
}

.profile-container {
    .profile-name {
        .title {
            color: $gold;
            font-size: 24px;
            letter-spacing: 0.75px;
            text-transform: uppercase;
        }
    }
    .profile-button-set {
        display: flex;
        justify-content: center;
    }
    .nav-item {
        .nav-link {
            color: $gray;
            &:hover {
                color: $gold;
            }
        }
        .nav-link.active {
            color: $gold;
        }
    }
}
