@media only screen and (min-width: 950px) and (max-width: 1280px) {
    .pro-sidebar {
        width: 20vw;
    }
    .admin-layout {
        .content-panel {
            .content-section {
                width: 80vw;
                padding: 80px 2%;
            }
        }
    }
}

@media only screen and (min-width: 769px) and (max-width: 949px) {
    .pro-sidebar {
        width: 25vw;
    }
    .admin-layout {
        .content-panel {
            .content-section {
                width: 75vw;
                padding: 80px 2%;
            }
        }
    }
}

@media (max-width: 768px) {
    .pro-sidebar.md {
        position: fixed;
        left: 0px;
    }
    .pro-sidebar.md.collapsed {
        left: 0px;
    }
    .admin-layout {
        .content-panel {
            .navbar {
                width: 100%;
            }
            .content-section {
                width: 100vw;
                padding: 80px 0;
                margin-left: 24%;
            }
        }
    }
    .pro-sidebar {
        width: 270px;
        min-width: 270px;
    }    
}
