.rdt_Table {
    .rdt_TableHead {
        .rdt_TableHeadRow {
            color: $base;
            font-size: 10px;
            text-transform: uppercase;
            font-weight: bold;
        }
    }

    .rdt_TableBody {
        .rdt_TableRow {
            .rdt_TableCell {
                font-size: 12px;
                .action-button {
                    .view-content {
                        cursor: pointer;
                    }
                }
            }
            &:hover {
                background-color: $gray-color-line;
            }
        }
    }
}

.rdt_Pagination {
    select {
        padding-right: 20px;
        font-size: 12px;
        border: 1px solid rgba(23, 36, 61, 0.1);
    }
}
