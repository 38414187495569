@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
/* override browser default */
html,
body {
    margin: 0;
    padding: 0;
    font-family: 'DM Sans', sans-serif;
    color: $base;
    overflow: hidden;
    height: 100vh;
}

/* use viewport-relative units to cover page fully */
body,
.root,
.App {
    height: 100vh;
}

p {
    font-size: 14px;
    line-height: 21px;
}

// Text
.title {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: $base;
    text-align: center;
}

//gold switch
.active-gold-switch {
    .react-switch-bg {
        opacity: 0.15;
        background: $gold !important;
    }

    .react-switch-handle {
        background: $gold !important;
    }
}

.react-switch-bg {
    opacity: 0.15;
    background: $gray !important;
}

.react-switch-handle {
    background: rgb(175, 175, 175) !important;
}

// Buttons
.default-button {
    background-color: $base-green !important;
    color: white !important;
    width: 100%;
    height: 56px;
    border: none !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    border-radius: 0px !important;
    box-shadow: none !important;
    transition: $transition;
    display: block !important;

    @media screen and (min-width: 425px) {
        max-width: 392px;
        /*margin-left: auto;
        margin-right: auto;*/
    }

    &:disabled {
        background-color: rgba($base, 0.12) !important;
        color: rgba($base, 0.3) !important;

        &:hover {
            background-color: rgba($base, 0.12) !important;
        }
    }

    &:hover {
        background-color: $base-green-hover !important;
    }

    svg {
        margin-left: 10px;
        vertical-align: middle;
    }
}

.danger-button {
    background-color: $red !important;
    color: white !important;
    width: 100%;
    height: 56px;
    border: none !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    border-radius: 0px !important;
    box-shadow: none !important;
    transition: $transition;
    display: block !important;

    @media screen and (min-width: 425px) {
        max-width: 392px;
        margin-left: auto;
        margin-right: auto;
    }

    &:disabled {
        background-color: rgba($base, 0.12) !important;
        color: rgba($base, 0.3) !important;

        &:hover {
            background-color: rgba($base, 0.12) !important;
        }
    }

    &:hover {
        background-color: $lightred !important;
    }

    svg {
        margin-left: 10px;
        vertical-align: middle;
    }
}

.default-button-gold {
    background-color: $gold !important;
    color: white !important;
    width: 100%;
    height: 40px;
    border: none !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    border-radius: 0px !important;
    box-shadow: none !important;
    transition: $transition;
    display: block !important;

    @media screen and (min-width: 425px) {
        max-width: 364px;
        margin-left: auto;
        margin-right: auto;
    }

    &:disabled {
        background-color: rgba($base, 0.12) !important;
        color: rgba($base, 0.3) !important;

        &:hover {
            background-color: rgba($base, 0.12) !important;
        }
    }

    svg {
        margin-left: 10px;
        vertical-align: middle;
    }
}

.light-button {
    background-color: white !important;
    color: $gray !important;
    width: 100%;
    height: 56px;
    border: none !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    border-radius: 0px !important;
    box-shadow: none !important;
    transition: $transition;
    display: block !important;

    margin-top: 20px;
    margin-bottom: 20px;

    .red-highlight {
        color: $red;
        font-size: 12px;
        letter-spacing: 1.25px;
        &:hover {
            color: #ca3a3a;
        }
    }

    .or-text {
        color: $gray;
        font-size: 12px;
        letter-spacing: 1.25px;
        &:hover {
            color: $gray !important;
        }
    }

    @media screen and (min-width: 425px) {
        max-width: 364px;
        margin-left: auto;
        margin-right: auto;
    }

    &:disabled {
        background-color: rgba($base, 0.12) !important;
        color: rgba($base, 0.3) !important;

        &:hover {
            background-color: rgba($base, 0.12) !important;
        }
    }

    &:hover {
        color: $base !important;
    }

    svg {
        margin-left: 10px;
        vertical-align: middle;
    }
}

.underlined-link-button {
    font-weight: 400 !important;
    background: none !important;
    border: none !important;
    padding: 0 !important;
    color: $base-green !important;
    text-decoration: underline !important;
    cursor: pointer !important;
    line-height: 21px !important;
    font-size: 14px !important;
    box-shadow: none !important;
    transition: $transition;

    &:hover {
        color: $base-green-hover !important;
    }
}

.link-button {
    color: $base-green !important;
    background-color: transparent !important;
    border: none !important;
    font-size: 13px !important;
    font-weight: 700 !important;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    text-decoration: none !important;
    padding: 0 !important;
    box-shadow: none !important;
    transition: $transition;

    &:hover {
        color: $base-green-hover !important;
    }
}

.cancel-button {
    color: $gray !important;
    margin-top: 10px;
    height: 56px;
    width: 100%;
    background-color: transparent !important;
    border: none !important;
    font-size: 13px !important;
    font-weight: 700 !important;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    text-decoration: none !important;
    padding: 0 !important;
    box-shadow: none !important;
    transition: $transition;
    display: block;

    &:hover {
        color: $base !important;
    }

    @media screen and (min-width: 425px) {
        max-width: 364px;
        margin-left: auto;
        margin-right: auto;
    }
}

.bordered-button {
    width: 100%;
    background-color: #fff !important;
    color: $base-green !important;
    height: 56px;
    border: 1px solid $base-green;
    font-size: 14px !important;
    font-weight: 500 !important;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    border-radius: 3px !important;
    box-shadow: none !important;
    transition: all 0.2s linear;
    display: block !important;

    @media screen and (min-width: 425px) {
        max-width: 364px;
        margin-left: auto;
        margin-right: auto;
    }
}

.bordered-button:hover {
    color: #fff !important;
    background-color: $base-green !important;
}

.button-space {
    padding: 1rem !important;
    margin-bottom: 1rem;
}

.button-space-below {
    margin-bottom: 1rem;
}

//Inputs
input,
select {
    border: 1px solid rgba($base, 0.38);
    // height: 56px !important;
    outline: $base;
    font-size: 16px;
    color: $base !important;
    background-color: transparent;
    border-radius: 0 !important;
    padding: 0 15px;

    &::placeholder {
        font-size: 16px;
        font-weight: 400;
        color: $gray;
        letter-spacing: 0.15px;
    }

    &:focus {
        box-shadow: none !important;
    }
}

.group input {
    height: 56px !important;
    margin-bottom: 1rem;
    min-width: 392px;
}

select {
    cursor: pointer;
}

textarea {
    border: 1px solid rgba($base, 0.38) !important;
    border-radius: 0 !important;
    padding: 15px !important;
    box-shadow: none !important;
    font-size: 16px !important;
    color: $base !important;

    &::placeholder {
        font-size: 16px;
        font-weight: 400;
        color: $gray !important;
        letter-spacing: 0.15px;
    }

    &:focus-visible {
        outline: none;
    }
}

input[type="checkbox"] {
    width: 18px !important;
    height: 18px !important;
    margin-right: 10px !important;
    border-radius: 2px !important;
    padding: 0;

    &:checked {
        background-color: $gold !important;
        border-color: $gold !important;
    }
}

input[type="radio"] {
    height: 20px !important;
    width: 20px !important;
    padding: 0;

    &:checked + .radio-circle {
        background-color: $gold;
        border-color: $gold;
        position: relative;

        &::after {
            content: "";
            height: 16px;
            width: 16px;
            border-radius: 50%;
            border: 3px solid $secondary;
            position: absolute;
            top: 0;
            left: 0px;
        }
    }
}

.radio-circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid rgba($base, 0.38);
    margin-right: 18px;
    cursor: pointer;
}

.group {
    position: relative;

    .top-label {
        position: absolute;
        top: -10px;
        left: 15px;
        background-color: white;
        font-size: 13px !important;
        padding: 0 5px;
        color: rgba($base, 0.7) !important;
        z-index: 10;
        line-height: 19px !important;
    }

    span {
        padding-left: 2px;
        color: red;
    }

    input[value=""] + .top-label,
    input:placeholder-shown + .top-label,
    textarea:placeholder-shown + .top-label {
        display: none;
    }
}

// Container
/*
.container {
    padding-left: 18px !important;
    padding-right: 18px !important;
}
*/

.s-form-container {
    padding-top: 40px;
    max-width: 500px;
    margin: auto;
    padding-bottom: 80px;

    .inputs {
        padding: 20px 0 20px 0 !important;
    }

    .another-title {
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        color: $base;
        margin-top: 40px;
    }

    .subtitle {
        color: rgba(23, 36, 61, 0.5);
        font-size: 13px;
        text-align: center;
        margin-bottom: 20px;
    }

    .group {
        padding-bottom: 40px;

        .sub-subtitle {
            color: rgba(23, 36, 61, 0.5);
            font-size: 13px;
            text-align: center;
            margin-bottom: 0px;
        }

        .right {
            text-align: right;
            padding-left: 12px;
        }

        textarea {
            width: 100%;
        }

        &.checkbox {
            .form-check {
                label {
                    margin-top: -1.75em;
                    padding-left: 6px;
                }
            }
        }

        .form-check {
            label {
                margin-top: 0.25em;
            }

            input {
                margin-right: 10px !important;
            }
        }
    }

    .group.charlimit {
        padding-bottom: 13px;
    }

    button.link-button {
        padding: 1rem !important;
        margin-bottom: 1rem;
        width: 100%;
    }
}

div.row {
    max-width: 100%;
    margin: auto;
}

// Img
.user-picture {
    border-radius: 50%;
    object-fit: cover;
}

//Unique select
.unique-select {
    margin-bottom: 0 !important;
}

.tooltip-text {
    font-size: 12px;
    font-weight: 400;
    color: $base-green;
    text-decoration: underline;
    margin-left: 15px;
}

.tooltip-text:hover {
    color: $base-green-hover;
    cursor: pointer;
}

//spinner
.spinner-container {
    text-align: center;
    padding: 20px;
}

/* Custom checked radio styling with pseudo element */
.radio-input-container {
    input[type="radio"] {
        opacity: 0;
    }
    label > input[type="radio"] + *::before {
        content: "";
        display: inline-block;
        vertical-align: bottom;
        width: 1.4rem;
        height: 1.4rem;
        margin-right: 0.3rem;
        border-radius: 50%;
        border-style: solid;
        border-width: 0.1rem;
        margin-left: -30px;
        margin-bottom: 6px;
        border-color: gray;
    }

    label {
        cursor: pointer;
        div {
            display: inline-block;
        }
        span {
            position: absolute;
            display: inline-block;
        }
    }

    label > input[type="radio"]:checked + *::before {
        background: radial-gradient($gold 0%, $gold 40%, transparent 50%, transparent);
        border-color: $gold;
    }
}
/*
.help-container {
    // margin-top: 10px;
    padding: 35px;
    border-top: 1px solid #f3f4f5;
    position: relative;

    text-align: center;

    button {
    }

    svg {
        position: absolute;
        right: 10px;

        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);

        color: rgba($gray, 0.3);
        font-size: 12px;
    }
}
*/
//unique date select container with standalone day, month and year
.dates-input-container {
    display: flex;
    justify-content: space-between;
    max-width: 500px;
    margin-bottom: 20px;

    .date-container {
        position: relative;
        max-width: 150px;
        width: 30%;

        &.disabled {
            background-color: $lightgray;
        }

        label {
            display: inline-block;
            position: absolute;
            top: -9px;
            left: 15px;
            background-color: white;
            font-size: 13px !important;
            padding: 0 5px;
            color: rgba(23, 36, 61, 0.7) !important;
            z-index: 10;
            line-height: 19px !important;
        }

        .calendar-icon {
            width: 1.5rem;
            height: 1.5rem;
            display: inline-block;
            position: absolute;
            top: 15px;
            right: 15px;
            background-color: none;
            color: rgba(23, 36, 61, 0.7) !important;
        }

        .calendar-close-icon {
            position: absolute;
            top: -8px;
            right: -8px;
            background-color: white;
            color: $gray;
        }

        .calendar-close-icon:hover {
            color: gray;
            cursor: pointer;
        }
    }
}

.input-error input,
input.input-error {
    border: 2px solid $red !important;
}
/*
.input-error input[type=checkbox] {
    outline: 2px solid $red !important;
}
*/
