.admin-layout {
    display: flex;
    height: 100%;
    .content-panel {
        overflow-y: auto;
        overflow-x: hidden;
        .content-section {
            width:85vw;
            padding:80px 2%;
            z-index: 1;
            .title {
                padding-bottom: 40px;
            }
        }
    } 
}
