.sidenav {
    border-right: 1px solid $lightgray;
    height: 100vh;
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header {
    text-align: center;
    border-bottom: 1px solid rgba(23, 36, 61, 0.1);
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-footer {
    border-top: 1px solid rgba(23, 36, 61, 0.1);
}

.iSAVrt {
    font-style: normal;
}

.pro-sidebar {
    width: 15vw;
    min-width: 15vw;
    .pro-menu {
        .pro-menu-item {
            font-size: 13px;
            .pro-inner-item {
                position: relative;
                display: flex;
                align-items: center;
                padding: 2px 35px 2px 20px;
                cursor: pointer;
            }
        }
        .pro-inner-list-item {
            padding-left: 10px!important;
            ul {
                padding-top: 5px!important; 
                padding-bottom: 5px!important;
           }
        }
        
    }
}
